.board {
    display: grid;
    grid-template-rows: repeat(6, var(--size));
    grid-template-columns: repeat(7, var(--size));
    position: relative;
}

.board div {
    background: url('./clip.svg');
}

.tile {
    width: var(--size);
    height: var(--size);
    display: inline-block;
    border: 1px solid #ccc;
}
