:root {
  --size : 100px;
  --p1 : #d9c65e;
  --p2 : #cb514f;
}


.App {
  display: grid;
  place-content: center;
}