.banner {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    color: #3a3a3a;
}

.banner.yellow {
    background: rgb(251, 251, 164);
}

.banner.red {
    background: rgb(248, 153, 153);
}
